.about-us {
  padding: 120px 0 80px;
  background-color: #050505;
  min-height: calc(100vh - 120px);
}

.team-intro {
  max-width: 800px;
  margin: 0 auto 60px;
  text-align: center;
  color: var(--light-gray);
  font-size: 1.1rem;
  line-height: 1.7;
}

.team-members {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 40px;
  margin-bottom: 60px;
}

.member-card {
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.member-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.4);
  border-color: var(--accent-color);
}

.member-image {
  width: 100%;
  height: 300px;
  overflow: hidden;
}

.member-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: transform 0.5s ease;
}

.member-card:hover .member-image img {
  transform: scale(1.05);
}

.member-info {
  padding: 25px;
}

.member-info h3 {
  font-size: 1.8rem;
  margin-bottom: 5px;
  background: var(--gradient-bg);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.member-info h4 {
  font-size: 1rem;
  margin-bottom: 15px;
  color: var(--light-gray);
}

.member-info p {
  margin-bottom: 20px;
  color: var(--light-gray);
  line-height: 1.6;
}

.member-skills {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.skill-tag {
  background-color: rgba(77, 145, 255, 0.1);
  color: var(--primary-color);
  padding: 5px 12px;
  border-radius: 20px;
  font-size: 0.8rem;
  transition: background-color 0.3s ease;
}

.skill-tag:hover {
  background-color: rgba(77, 145, 255, 0.2);
}

.join-team {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.join-team h3 {
  font-size: 2rem;
  margin-bottom: 20px;
  background: var(--gradient-bg);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.join-team p {
  margin-bottom: 30px;
  color: var(--light-gray);
  font-size: 1.1rem;
}

@media (max-width: 768px) {
  .about-us {
    padding: 120px 0 60px;
  }
  
  .team-members {
    grid-template-columns: 1fr;
  }
  
  .member-image {
    height: 250px;
  }
  
  .member-info h3 {
    font-size: 1.5rem;
  }
  
  .join-team {
    padding: 30px 20px;
  }
  
  .join-team h3 {
    font-size: 1.8rem;
  }
} 