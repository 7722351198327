.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
/* Markdown風格的計算器容器 */
.rent-calculator-container {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  color: #333333;
  background-color: #f9fafc;
  letter-spacing: 0.2px;
}

/* 確保頁面有足夠的頂部間距，避免被固定導航欄遮擋 */
.page-transition.rent-calculator-container {
  padding-top: 100px;
  min-height: calc(100vh - 80px);
  /* 避免父頁面的footer樣式影響 */
  position: relative;
  z-index: 1;
}

/* 隱藏App.js中的footer，只顯示計算器頁面自己的footer */
.rent-calculator-container + footer {
  display: none !important;
}

/* 為計算器頁面提供自己的footer，類似MD文檔底部 */
.rent-calculator-container .calculator-footer {
  margin-top: 30px;
  text-align: center;
  padding: 15px 0;
  border-top: 1px solid #eee;
  color: #999;
  font-size: 14px;
}

/* Markdown風格的標題 */
.rent-calculator-container h1 {
  font-size: 2em;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
  color: #333;
}

.rent-calculator-container h2 {
  font-size: 1.5em;
  margin-top: 24px;
  margin-bottom: 16px;
  padding-bottom: 8px;
  color: #333;
  border-bottom: 1px solid #eee;
}

.rent-calculator-container h3 {
  font-size: 1.25em;
  margin-top: 24px;
  margin-bottom: 16px;
  color: #4D91FF;
}

/* Markdown風格的段落和文本 */
.rent-calculator-container p {
  margin-bottom: 16px;
  line-height: 1.6;
  color: #555;
}

/* Markdown風格的引用塊 */
.rent-calculator-container .md-blockquote {
  padding: 0 1em;
  border-left: 4px solid #e1edff;
  color: #666;
  margin-bottom: 16px;
}

/* Markdown風格的代碼塊 */
.rent-calculator-container .md-codeblock {
  background-color: #f5f5f5;
  border-radius: 6px;
  padding: 16px;
  margin-bottom: 16px;
  overflow: auto;
  font-family: SFMono-Regular, Consolas, "Liberation Mono", Menlo, monospace;
  font-size: 85%;
  line-height: 1.45;
  color: #333;
}

/* Markdown風格的水平線 */
.rent-calculator-container hr {
  height: 0.25em;
  padding: 0;
  margin: 24px 0;
  background-color: #eee;
  border: 0;
}

/* Markdown風格的列表 */
.rent-calculator-container ul {
  margin-bottom: 16px;
  padding-left: 2em;
  color: #555;
}

.rent-calculator-container ul li {
  margin-bottom: 8px;
}

/* 響應式調整 */
@media (max-width: 768px) {
  .rent-calculator-container {
    padding: 15px;
  }
  
  .page-transition.rent-calculator-container {
    padding-top: 80px;
  }
}

/* 增加轉場動畫一致性 */
.page-transition {
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Markdown風格的輸入控件 */
.rent-calculator-container input,
.rent-calculator-container select {
  background-color: #ffffff;
  color: #333;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 10px;
  width: 100%;
  font-family: inherit;
  font-size: 14px;
  transition: border-color 0.2s ease;
}

.rent-calculator-container input:focus,
.rent-calculator-container select:focus {
  border-color: #4D91FF;
  outline: none;
  box-shadow: 0 0 0 3px rgba(77, 145, 255, 0.2);
}

.rent-calculator-container input::placeholder {
  color: #aaa;
}

/* Markdown風格的按鈕 */
.rent-calculator-container button {
  background: linear-gradient(to right, #4D91FF, #17E7E7, #B859FF);
  color: #ffffff;
  border: none;
  border-radius: 8px;
  padding: 14px 20px;
  font-size: 16px;
  font-weight: bold;
  transition: transform 0.2s, box-shadow 0.2s;
  cursor: pointer;
  width: 100%;
  margin-top: 15px;
  box-shadow: 0 3px 10px rgba(69, 104, 220, 0.3);
}

.rent-calculator-container button:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 15px rgba(69, 104, 220, 0.4);
}

/* Markdown風格的表格 */
.rent-calculator-container table {
  width: 100%;
  margin-bottom: 16px;
  border-spacing: 0;
  border-collapse: collapse;
}

.rent-calculator-container th {
  font-weight: 600;
  padding: 8px 16px;
  text-align: left;
  border-bottom: 1px solid #eee;
  background-color: #f8f9fa;
  color: #333;
}

.rent-calculator-container td {
  padding: 8px 16px;
  border-bottom: 1px solid #eee;
  color: #555;
}

.rent-calculator-container tr:nth-child(2n) {
  background-color: #fafafa;
}

/* 卡片容器 */
.md-card {
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 6px;
  padding: 16px;
  margin-bottom: 16px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
}

/* 區塊分隔 */
.md-section {
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #eee;
}

/* 鏈接樣式 */
.rent-calculator-container a {
  color: #4D91FF;
  text-decoration: none;
}

.rent-calculator-container a:hover {
  text-decoration: underline;
}

/* 漸變色的描述框 */
.md-blockquote {
  background: linear-gradient(to right, rgba(77, 145, 255, 0.1), rgba(23, 231, 231, 0.1), rgba(184, 89, 255, 0.1));
  border-left: 4px solid #4D91FF;
  border-radius: 6px;
  padding: 15px;
  margin-bottom: 20px;
  color: #333;
}

/* 漸變色標題 */
.gradient-text {
  background: linear-gradient(to right, #4D91FF, #17E7E7, #B859FF);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
  