/* 全局变量 */
:root {
  --primary-color: #4D91FF;
  --secondary-color: #B859FF;
  --accent-color: #17E7E7;
  --dark-color: #333333;
  --light-color: #ffffff;
  --gray-color: #f5f5f5;
  --light-gray: #e0e0e0;
  --gradient-bg: linear-gradient(to right, var(--primary-color), var(--accent-color), var(--secondary-color));
  --font-family: 'Inter', sans-serif;
  --transition: all 0.3s ease;
}

/* 重置样式 */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: var(--font-family);
  background-color: #f9fafc;
  color: #333333;
  line-height: 1.6;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style: none;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

/* 按钮样式 */
.btn {
  display: inline-block;
  padding: 12px 30px;
  border-radius: 30px;
  font-weight: bold;
  cursor: pointer;
  transition: var(--transition);
  text-align: center;
}

.btn-primary {
  background: var(--primary-color);
  color: var(--light-color);
  box-shadow: 0 4px 15px rgba(77, 145, 255, 0.3);
}

.btn-primary:hover {
  background: #3a7fe6;
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(77, 145, 255, 0.4);
}

.btn-secondary {
  background: transparent;
  color: var(--light-color);
  border: 1px solid var(--light-color);
}

.btn-secondary:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: translateY(-2px);
}

/* 页面过渡效果 */
.page-transition {
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* 头部导航 */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 15px 0;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
  gap: 10px;
}

.logo img {
  height: 50px;
  width: auto;
  object-fit: contain;
  transition: transform 0.3s ease;
}

.logo a:hover img {
  transform: scale(1.05);
}

.logo h1 {
  font-size: 1.8rem;
  background: var(--gradient-bg);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: bold;
}

.main-nav {
  flex: 1;
  display: flex;
  justify-content: center;
}

.main-nav ul {
  display: flex;
  gap: 30px;
}

.main-nav a {
  font-weight: 500;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.7);
  transition: var(--transition);
  display: flex;
  align-items: center;
}

.main-nav a:hover {
  color: var(--primary-color);
}

.main-nav a .arrow {
  margin-left: 5px;
  font-size: 0.8rem;
  transition: transform 0.3s ease;
}

/* 下拉菜单样式 */
.dropdown {
  position: relative;
}

.dropdown-content {
  position: static;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 0;
  padding: 0;
  margin-top: 0;
  opacity: 1;
  visibility: hidden;
  transform: none;
  box-shadow: none;
  border: none;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease, padding 0.3s ease, opacity 0.2s ease;
  display: block;
}

.dropdown-content a {
  padding: 10px 20px;
  display: block;
  color: rgba(0, 0, 0, 0.7);
  transition: background-color 0.2s ease;
}

.dropdown-content a:hover {
  background-color: rgba(0, 0, 0, 0.05);
  color: var(--primary-color);
}

.dropdown.active .dropdown-content {
  visibility: visible;
  max-height: 300px;
  padding: 5px 0;
  opacity: 1;
}

.dropdown > a .arrow {
  transition: transform 0.3s ease;
}

.dropdown.active > a .arrow {
  transform: rotate(180deg);
}

.mobile-nav-toggle {
  display: none;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
  z-index: 1001;
}

.mobile-nav-toggle span {
  display: block;
  width: 25px;
  height: 2px;
  background-color: #333;
  transition: var(--transition);
}

/* 响应式导航 */
@media (max-width: 1100px) {
  .main-nav ul {
    gap: 20px;
  }
}

@media (max-width: 768px) {
  .main-nav {
    position: fixed;
    top: 70px;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.95);
    padding: 20px 0;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    transform: translateY(-100%);
    opacity: 0;
    visibility: hidden;
    transition: transform 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
  }

  .main-nav.active {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }

  .main-nav ul {
    flex-direction: column;
    gap: 0;
    width: 100%;
    padding: 0 20px;
  }

  .dropdown-content {
    position: static;
    width: 100%;
    background-color: rgba(245, 245, 245, 0.8);
    border-radius: 0;
    display: block;
    box-shadow: none;
    border: none;
    visibility: hidden;
    max-height: 0;
    transition: max-height 0.3s ease, padding 0.3s ease;
    overflow: hidden;
    opacity: 1;
    margin-left: 15px;
    padding: 0;
  }
  
  .dropdown.active .dropdown-content {
    visibility: visible;
    max-height: 300px;
    padding: 5px 0;
  }

  .dropdown {
    width: 100%;
    cursor: pointer;
  }

  .dropdown > a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .dropdown > a .arrow {
    transform: none;
    transition: transform 0.3s ease;
  }

  .dropdown.active > a .arrow {
    transform: rotate(180deg);
  }

  .dropdown.active {
    background-color: rgba(0, 0, 0, 0.03);
  }

  .dropdown-content a {
    padding: 10px 20px;
    color: rgba(0, 0, 0, 0.7);
  }

  .dropdown-content a:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  .hero-content h2 {
    font-size: 2rem;
  }

  .hero-content p {
    font-size: 1rem;
  }

  .hero-buttons {
    flex-direction: column;
  }

  .section-header h2 {
    font-size: 1.8rem;
  }

  .feature-cards {
    grid-template-columns: 1fr;
  }

  .ecosystem-grid {
    grid-template-columns: 1fr;
  }

  .footer-grid {
    grid-template-columns: 1fr;
  }

  .mobile-nav-toggle {
    display: flex;
  }

  .desktop-only {
    display: none;
  }
}

@media (min-width: 769px) {
  .hamburger {
    display: none !important;
  }
}

/* 漢堡菜單定位 */
.hamburger {
  display: block;
  z-index: 1001;
}

/* 覆蓋原有的mobile-nav-toggle樣式 */
.mobile-nav-toggle {
  display: none;
}

/* 英雄部分 */
.hero {
  position: relative;
  height: 100vh;
  min-height: 600px;
  background-color: #e6f0ff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden;
}

.hero:before {
  content: '';
  position: absolute;
  width: 150%;
  height: 150%;
  background: linear-gradient(45deg, rgba(77, 145, 255, 0.1), rgba(23, 231, 231, 0.1), rgba(184, 89, 255, 0.1));
  animation: rotate 20s linear infinite;
  z-index: 1;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.hero-content {
  position: relative;
  z-index: 2;
  max-width: 800px;
  padding: 0 20px;
}

.hero-content h2 {
  font-size: 3.5rem;
  font-weight: 800;
  letter-spacing: -0.5px;
  margin-bottom: 20px;
  background: linear-gradient(to right, #4D91FF, #17E7E7);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.hero-content p {
  font-size: 1.2rem;
  margin-bottom: 30px;
  color: #555;
}

.hero-buttons {
  display: flex;
  gap: 20px;
  justify-content: center;
}

/* 特性部分 */
.features {
  padding: 100px 0;
}

.section-header {
  text-align: center;
  margin-bottom: 60px;
}

.section-header h2 {
  font-size: 2.5rem;
  font-weight: 800;
  letter-spacing: -0.5px;
  margin-bottom: 20px;
  background: linear-gradient(to right, #4D91FF, #17E7E7);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline-block;
}

.section-header p {
  color: #555;
}

.feature-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 30px;
}

.feature-card {
  background-color: #fff;
  border-radius: 15px;
  padding: 30px;
  text-align: center;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.feature-icon {
  font-size: 3rem;
  margin-bottom: 20px;
}

.feature-card h3 {
  margin-bottom: 15px;
  color: #4D91FF;
}

.feature-card p {
  color: #555;
}

/* 生态系统部分 */
.ecosystem {
  padding: 100px 0;
  background-color: #e6f0ff;
}

.ecosystem-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px;
}

.ecosystem-item {
  background-color: #fff;
  border-radius: 15px;
  padding: 30px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.ecosystem-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.ecosystem-item h3 {
  color: #4D91FF;
  margin-bottom: 15px;
}

.ecosystem-item p {
  color: #555;
}

/* 行动号召部分 */
.cta {
  padding: 100px 0;
}

.cta-content {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.cta-content h2 {
  color: #4D91FF;
  margin-bottom: 20px;
}

.cta-content p {
  color: #555;
  margin-bottom: 30px;
}

/* 页脚部分 */
.footer {
  padding: 80px 0 30px;
  background-color: #f5f5f5;
}

.footer-grid {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  gap: 40px;
  margin-bottom: 60px;
}

.footer-col h3 {
  font-size: 1.8rem;
  background: var(--gradient-bg);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  margin-bottom: 20px;
  font-weight: bold;
}

.footer-col h4 {
  color: #4D91FF;
  margin-bottom: 20px;
  font-size: 1.2rem;
}

.footer-col p {
  color: #555;
  margin-bottom: 20px;
}

.footer-col ul li {
  margin-bottom: 10px;
}

.footer-col ul li a {
  color: #555;
  transition: color 0.2s ease;
}

.footer-col ul li a:hover {
  color: #4D91FF;
}

.footer-bottom {
  text-align: center;
  padding-top: 30px;
  border-top: 1px solid #eee;
}

.footer-bottom p {
  color: #999;
  font-size: 0.9rem;
}

/* 桌面端下拉菜單樣式 */
@media (min-width: 769px) {
  .dropdown-content {
    position: absolute;
    top: 100%;
    left: 0;
    width: 220px;
    background-color: #151515;
    border-radius: 8px;
    padding: 10px 0;
    margin-top: 15px;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
    transition: all 0.3s ease;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    border: 1px solid rgba(255, 255, 255, 0.1);
    z-index: 100;
    max-height: none;
    overflow: visible;
    display: block;
  }

  .dropdown:hover .dropdown-content {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    max-height: none;
  }

  .dropdown:hover .arrow {
    transform: rotate(180deg);
  }
}

/* 主内容区域调整，为固定顶部导航腾出空间 */
main {
  padding-top: 80px;
}

/* 页面内容区域 */
.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.app > footer {
  margin-top: auto;
}
