/* 移動端菜單樣式 */
.mobile-menu {
  position: fixed;
  top: 70px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.95);
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: all 0.3s ease-in-out;
  overflow-y: auto;
}

.mobile-menu.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.mobile-menu-container {
  padding: 20px;
}

.mobile-nav-items {
  list-style: none;
  padding: 0;
  margin: 0;
}

.mobile-nav-item {
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.mobile-nav-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.1rem;
  font-weight: 500;
  transition: all 0.2s ease;
  text-decoration: none;
}

.mobile-nav-link:hover {
  color: var(--primary-color);
}

.mobile-nav-link .arrow {
  font-size: 0.8rem;
  transition: transform 0.3s ease;
}

.mobile-nav-link .arrow.active {
  transform: rotate(180deg);
}

.mobile-dropdown-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  background-color: rgba(77, 145, 255, 0.05);
  border-left: 2px solid var(--primary-color);
  margin-left: 10px;
}

.mobile-dropdown-content.active {
  max-height: 300px;
}

.mobile-dropdown-link {
  display: block;
  padding: 12px 15px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 1rem;
  transition: all 0.2s ease;
  text-decoration: none;
}

.mobile-dropdown-link:hover {
  background-color: rgba(77, 145, 255, 0.1);
  color: var(--accent-color);
}

/* 關閉按鈕動畫 */
.hamburger {
  width: 30px;
  height: 25px;
  position: relative;
  cursor: pointer;
  display: inline-block;
}

.hamburger span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: var(--light-color);
  border-radius: 3px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

.hamburger span:nth-child(1) {
  top: 0px;
}

.hamburger span:nth-child(2) {
  top: 10px;
}

.hamburger span:nth-child(3) {
  top: 20px;
}

.hamburger.active span:nth-child(1) {
  top: 10px;
  transform: rotate(135deg);
}

.hamburger.active span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

.hamburger.active span:nth-child(3) {
  top: 10px;
  transform: rotate(-135deg);
} 